import React from 'react';
import { IFilter } from '../../hooks/table/useFilter';
import Modal from '../common/Modal';

type Props = {
  options: { [field: string]: string[] | number[] };
  defaultFilterValues: IFilter<
    | {
        [field: string]: string[] | number[];
      }
    | undefined
  >;
  onRequestClose: () => void;
  handleFilter: (
    filter: IFilter<
      | {
          [field: string]: string[] | number[];
        }
      | undefined
    >,
  ) => void;
};

const Filters = ({
  options,
  handleFilter,
  defaultFilterValues,
  onRequestClose: closeModal,
}: Props) => {
  const onSubmit = (target: any) => {
    const body: any = {};
    for (let i = 0; i < target.length - 1; i++) {
      body[target[i].name] = target[i].value;
    }
    handleFilter(body);
    closeModal();
  };
  return (
    <Modal onRequestClose={closeModal}>
      <div id="filters" className="filters-modal">
        <div className="modal-body">
          <div className="modal-header">
            <p className="modal-title">Filters</p>
            <button className="modal-close" onClick={closeModal}>
              &nbsp;
            </button>
          </div>
          <div className="modal-content">
            <form
              className="modal-form"
              onSubmit={(e) => {
                e.preventDefault();
                onSubmit(e.target);
              }}
            >
              {options &&
                Object.keys(options).map((field) => (
                  <div key={field} className="modal-form_col">
                    <div className="form-item">
                      <label className="form-label">
                        {field === 'surface' ? 'size:' : `${field}:`}
                      </label>
                      <div className="select-container">
                        <select
                          name={field}
                          className="select"
                          defaultValue={
                            defaultFilterValues
                              ? defaultFilterValues[field]
                              : ''
                          }
                        >
                          <option value={''}>Select</option>
                          {options[field].map((item) => (
                            <option key={item} value={item}>
                              {field === 'surface' ? `${item} Sq. ft.` : item}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                ))}
              <button type="submit" className="btn">
                Apply
              </button>
            </form>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default Filters;
