import { useEffect, useState } from 'react';
import { IProperty } from '../../components/apartments/Apartments';

export const useSort = (data: IProperty[]) => {
  const [isInitSort, setIsInitSort] = useState(true);
  const [items, setItems] = useState<IProperty[]>([]);
  const [sortOptions, setSortOptions] = useState(
    {} as {
      field: string;
      isAsc: boolean;
    },
  );

  useEffect(() => {
    if (data.length > 0 && isInitSort) {
      handleSort('aptNo');
      setIsInitSort(false);
    }
  }, [data]);

  useEffect(() => {
    if (Object.keys(sortOptions).length > 0) {
      onSort();
    }
  }, [sortOptions]);

  const handleSort = (sort: string) => {
    if (!data.length) {
      return;
    }
    setSortOptions({
      field: sort,
      isAsc: sort === sortOptions?.field ? !sortOptions.isAsc : true,
    });
  };

  const onSort = () => {
    setItems(() => {
      switch (sortOptions!.field) {
        case 'aptNo': {
          return [...sortByApartmentNumber()];
        }
        case 'floor': {
          return [...sortByFloor()];
        }
        case 'surface':
        case 'price': {
          return [...sortByNumber()];
        }
        default: {
          return [...defaultSort()];
        }
      }
    });
  };

  const sortByFloor = () => {
    return data.sort((a, b) => {
      if (a.floor === 'Ground floor') {
        return sortOptions!.isAsc ? -1 : 1;
      } else if (b.floor === 'Ground floor') {
        return sortOptions!.isAsc ? 1 : -1;
      } else {
        return sortOptions!.isAsc
          ? a.floor.localeCompare(b.floor)
          : b.floor.localeCompare(a.floor);
      }
    });
  };
  const sortByApartmentNumber = () => {
    return data.sort((a, b) => {
      const aNumber = +a.aptNo.match(/\d+/)![0];
      const bNumber = +b.aptNo.match(/\d+/)![0];
      return sortOptions!.isAsc ? aNumber - bNumber : bNumber - aNumber;
    });
  };
  const sortByNumber = () => {
    return data.sort((a: any, b: any) => {
      if (!a[sortOptions!.field]) {
        return 1;
      }
      if (!b[sortOptions!.field]) {
        return -1;
      }
      return sortOptions!.isAsc
        ? a[sortOptions!.field] - b[sortOptions!.field]
        : b[sortOptions!.field] - a[sortOptions!.field];
    });
  };
  const defaultSort = () => {
    return data.sort((a: any, b: any) => {
      if (!a[sortOptions!.field]) {
        return 1;
      }
      if (!b[sortOptions!.field]) {
        return -1;
      }
      return sortOptions!.isAsc
        ? a[sortOptions!.field].localeCompare(b[sortOptions!.field])
        : b[sortOptions!.field].localeCompare(a[sortOptions!.field]);
    });
  };

  return { items, handleSort, sortOptions };
};
