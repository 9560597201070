import React from 'react';
import historyImage from '../assets/images/pic-history.webp';
import history1Image from '../assets/images/pic-history1.webp';
import history2Image from '../assets/images/pic-history2.webp';
import history3Image from '../assets/images/pic-history3.webp';
import history4Image from '../assets/images/pic-history4.webp';
import history5Image from '../assets/images/pic-history5.webp';

const History = () => {
  return (
    <section className="section section-history">
      <div className="container">
        <h3>— History</h3>
        <div className="section-history_content">
          <div className="pic-container">
            <figure className="pic-main">
              <img src={historyImage} alt="" />
            </figure>
            <figure className="pic-small pic-left">
              <img src={history1Image} alt="" />
            </figure>
            <figure className="pic-small pic-right">
              <img src={history2Image} alt="" />
            </figure>
          </div>
          <article className="pic-text_text mb-36">
            K2 celebrates Kingston’s intangible cultural wealth as a UNESCO “Creative City of Music” and represents the rise of the economic potential of Jamaica. In its prime, Kingston 2 was the home of the Bournemouth Club, a locally cherished destination and world-famous for its waterfront lifestyle, lush gardens and entertainment that helped birth Jamaican Jazz and Ska. Today, major investments in corporate and governmental headquarters and infrastructure are transforming Downtown Kingston into the Caribbean’s premier logistics and trade hub.
          </article>
          <div className="pic-text_container">
            <figure className="pic-main">
              <img src={history3Image} alt="" />
            </figure>
            <figure className="pic-small pic-left">
              <img src={history4Image} alt="" />
            </figure>
            <article className="pic-text_text pic-text_text2">
              Vibrant artist murals on streets and lanes mark the reawakening of Kingston’s city centre as a destination of choice for cultural, social, and economic renewal.
              As the largest residential investment in over 30 years, the K2 development will redefine the Downtown Kingston skyline, and transform the surrounding community. It will stand as a foundational pillar for what is next to come in the emerging wave of reimagining, reawakening and rebirth for Kingston 2.0.
            </article>
            <figure className="pic-small pic-right">
              <img src={history5Image} alt="" />
            </figure>
          </div>
        </div>
      </div>
    </section>
  );
};

export default History;
