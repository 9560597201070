import React from 'react';
import { FieldErrors, FieldValues } from 'react-hook-form';
interface IProps {
  name: string;
  errors: FieldErrors<FieldValues>;
  type: string;
  placeholder?: string;
}

const InputField = React.forwardRef(
  ({ errors, name, type, placeholder }: IProps, ref: any) => {
    const error = errors[name];
    return (
      <div className="modal-form_col">
        <div className={`form-item ${error ? 'error' : ''}`}>
          <input type={type} placeholder={placeholder} {...ref} />
          {error && <p className="error-text">{error.message}</p>}
        </div>
      </div>
    );
  },
);
export default InputField;
