import React from 'react';
import Popup from 'reactjs-popup';
import { PopupPosition } from 'reactjs-popup/dist/types';
import { IApartment } from './ApartmentSchema';

interface IProps {
  children: JSX.Element;
  item: IApartment;
  position: PopupPosition;
  openApartmentModal: (item: IApartment) => void;
}

const Tooltip = ({ children, item, position, openApartmentModal }: IProps) => {
  return (
    <Popup trigger={children} position={position} on={['hover', 'focus']}>
      <div className="container">
        <div className="content">
          <div>
            <div className="caption">APT NO.</div>
            <div>{item.aptNo}</div>
          </div>
          <div>
            <div className="caption">TYPE</div>
            <div>{item.type}</div>
          </div>
          <div>
            <div className="caption">STATUS</div>
            <div className={item.status.toLowerCase()}>{item.status}</div>
          </div>
        </div>
        <div className="content">
          <div>
            <div className="caption">SIZE</div>
            <div>{item.surface} Sq. ft.</div>
          </div>
          <div>
            <div className="caption">PRICE</div>
            <div>${item.price}</div>
          </div>
        </div>
      </div>
      <button className="btn" onClick={() => openApartmentModal(item)}>
        see details
      </button>
    </Popup>
  );
};

export default Tooltip;
