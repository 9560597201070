import { RefObject, useState } from 'react';

const usePanoramaScroll = (scrollRef: RefObject<HTMLDivElement>) => {
  const [lastDrag, setLastDrag] = useState(0);

  const handleChangePosition = (n: number) => {
    if (lastDrag && n) {
      const tmp = lastDrag - n;
      if (scrollRef.current) {
        scrollRef.current.scrollLeft += tmp;
      }
      return setLastDrag(n);
    }
    setLastDrag(n);
  };

  return { handleChangePosition };
};

export default usePanoramaScroll;
