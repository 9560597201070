import React, { useCallback, useState } from 'react';
import Slider from 'react-slick';
import ImageViewer from 'react-simple-image-viewer';

function SampleNextArrow(props: any) {
  const { className, onClick } = props;
  return <div className={className} onClick={onClick} />;
}

function SamplePrevArrow(props: any) {
  const { className, onClick } = props;
  return <div className={className} onClick={onClick} />;
}

const images = [
  require('../assets/images/slider/Front.webp'),
  require('../assets/images/slider/Lounge.webp'),
  require('../assets/images/slider/Living.webp'),
  require('../assets/images/slider/Kitchen.webp'),
  require('../assets/images/slider/Bedroom.webp'),
  require('../assets/images/slider/Pool.webp'),
  require('../assets/images/slider/Back.webp'),
];

const Gallery = () => {
  const [currentImage, setCurrentImage] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);

  const openImageViewer = useCallback((index) => {
    setCurrentImage(index);
    setIsViewerOpen(true);
  }, []);

  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerMode: true,
    variableWidth: true,
    rows: 1,
    swipe: true,
    slidesPerRow: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    customPaging: (i: number) => (
      <div style={{ cursor: 'pointer' }}>{i < 10 ? `0${i + 1}` : i + 1}</div>
    ),
    responsive: [
      {
        breakpoint: 768,
        settings: {
          centerMode: false,
          variableWidth: false,
          dots: false,
          arrows: false,
        },
      },
    ],
  };

  return (
    <section className="section-gallery" id="gallery">
      <div className="container">
        <h3>— Gallery</h3>
        <Slider {...settings} className="my-slick">
          {images.map((image, index) => (
            <div key={index}>
              <img
                style={{ cursor: 'pointer' }}
                src={image}
                alt="gallery-image"
                onClick={() => openImageViewer(index)}
              />
            </div>
          ))}
        </Slider>
        {isViewerOpen && (
          <ImageViewer
            src={images}
            currentIndex={currentImage}
            disableScroll={false}
            closeOnClickOutside={true}
            onClose={closeImageViewer}
          />
        )}
      </div>
    </section>
  );
};

export default Gallery;
