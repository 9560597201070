import { useState } from 'react';

const useModal = (initial: boolean) => {
  const [showModal, setShowModal] = useState(initial);

  const closeModal = () => setShowModal(false);
  const openModal = () => setShowModal(true);

  return [showModal, openModal, closeModal] as const;
};

export default useModal;
