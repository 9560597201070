import React, { LegacyRef, useEffect, useState } from 'react';
import { PopupPosition } from 'reactjs-popup/dist/types';
import groundScheme from '../../assets/images/floorsSchema/Ground Floor.webp';
import floorScheme from '../../assets/images/floorsSchema/1st-8th Floor.webp';
import topFloorScheme from '../../assets/images/floorsSchema/9th Floor.webp';
import useModal from '../../hooks/useModal';
import ApartmentModal from '../modals/ApartmentModal';
import { IFloor, IProperty } from './Apartments';
import Tooltip from './Tooltip';
import {
  floorCoords,
  groundFloorCoords,
  topFloorCoords,
} from './mock/floorsSchema';

interface IProps {
  apartments: IProperty[];
  floors: IFloor[];
  currentFloor: string;
  anchordRef: LegacyRef<HTMLDivElement>;
}

export interface IApartment extends IProperty {
  inset: string;
  clipPath?: string;
  position: string;
}

const ApartmentSchema: React.FC<IProps> = ({
  apartments: data,
  floors,
  currentFloor,
  anchordRef,
}) => {
  const [showApartmentModal, openApartmentModal, closeApartmentModal] =
    useModal(false);
  const [apartments, setApartments] = useState<IApartment[]>([]);
  const [apartment, setApartment] = useState<IProperty | undefined>(undefined);
  const [activeFloor, setActiveFloor] = useState('');

  useEffect(() => {
    setActiveFloor(currentFloor);
  }, [currentFloor]);

  useEffect(() => {
    if (data.length > 0) {
      setApartments(
        data
          .filter((item) => item.floor === activeFloor)
          .sort((a, b) => {
            const aNumber = +a!.aptNo!.match(/\d+/)![0];
            const bNumber = +b.aptNo.match(/\d+/)![0];
            return bNumber - aNumber;
          })
          .map((el, index) => ({
            ...el,
            ...getFloorCoords(el.floor)[index],
          })),
      );
    }
  }, [data, activeFloor]);

  const openModal = (item: IApartment) => {
    const el = data.find((el) => el.aptNo === item.aptNo);
    setApartment(el);
    openApartmentModal();
  };

  const getFloorSchema = (floor: string) => {
    if (floor === 'Ground floor') {
      return groundScheme;
    }
    if (floor === '9th Floor') {
      return topFloorScheme;
    }
    return floorScheme;
  };

  const getFloorCoords = (floor: string) => {
    if (floor === 'Ground floor') {
      return groundFloorCoords;
    }
    if (floor === '9th Floor') {
      return topFloorCoords;
    }
    return floorCoords;
  };

  return (
    <div ref={anchordRef} className="section-scheme">
      <div className="header">
        {floors.map((floor) => (
          <button
            key={floor.name}
            className={activeFloor === floor.name ? 'item-active' : 'item'}
            onClick={() => setActiveFloor(floor.name)}
          >
            {floor.name}
          </button>
        ))}
      </div>
      <ul className="section-scheme_caption">
        <li>
          <span className="icon-marker sold"></span>
          <span>Sold</span>
        </li>
        <li>
          <span className="icon-marker available"></span>
          <span>Available</span>
        </li>
        <li>
          <span className="icon-marker reserved"></span>
          <span>Reserved</span>
        </li>
      </ul>
      <div className="section-scheme_scheme">
        <figure className="main-pic">
          <img src={getFloorSchema(apartments[0]?.floor)} alt="floor schema" />
        </figure>
        {apartments.length > 0 &&
          apartments.map((item) => (
            <div
              key={item.aptNo}
              className={`apartment ${item.status.toLowerCase()}`}
              style={{
                position: 'absolute',
                inset: item.inset,
                clipPath: item.clipPath,
              }}
            >
              <Tooltip
                item={item}
                position={item.position as PopupPosition}
                openApartmentModal={openModal}
              >
                <div style={{ position: 'absolute', inset: '0 0 0 0' }}></div>
              </Tooltip>
            </div>
          ))}
      </div>
      {showApartmentModal && apartment && (
        <ApartmentModal onRequestClose={closeApartmentModal} item={apartment} />
      )}
    </div>
  );
};

export default ApartmentSchema;
