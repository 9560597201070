import { instanceHS, instanceWD } from './instance';
import { AxiosResponse } from 'axios';
import config from '../config';

const service = {
  contactForm: (data: any) => {
    const formData = new FormData();
    formData.append('project', 'k2');
    formData.append('source', 'visualizer');
    formData.append('action', 'contact_request');

    formData.append('message', data.message || '');
    formData.append('contact[first_name]', data.firstName);
    formData.append('contact[last_name]', data.lastName);
    formData.append('contact[email]', data.email);
    formData.append('contact[phone]', data.phone);

    formData.append('callmeback', data.callMeBack ? '1' : (null as any));
    formData.append('emailmeback', data.emailMeBack ? '1' : (null as any));

    const config: any = {
      method: 'post',
      url: 'submitRequest',
      data: formData,
      redirect: 'follow',
    };

    return customFetch(() => instanceWD(config));
  },
  downloadBrochure: (data: any) => {
    const formData = new FormData();
    formData.append('project', 'k2');
    formData.append('source', 'visualizer');
    formData.append('action', 'brochure_download');

    formData.append('contact[first_name]', data.firstName);
    formData.append('contact[last_name]', data.lastName);
    formData.append('contact[email]', data.email);
    formData.append('contact[phone]', data.phone);

    const config: any = {
      method: 'post',
      url: 'submitRequest',
      data: formData,
      redirect: 'follow',
    };

    return customFetch(() => instanceWD(config));
  },
  downloadPlan: (data: any) => {
    const formData = new FormData();
    formData.append('project', 'k2');
    formData.append('source', 'visualizer');
    formData.append('action', 'floorplan_download');

    formData.append('contact[first_name]', data.firstName);
    formData.append('contact[last_name]', data.lastName);
    formData.append('contact[email]', data.email);
    formData.append('contact[phone]', data.phone);
    formData.append('property[rooms]', data.rooms);

    const config: any = {
      method: 'post',
      url: 'submitRequest',
      data: formData,
      redirect: 'follow',
    };

    return customFetch(() => instanceWD(config));
  },
  getProperties: async () => {
    return customFetch(() =>
      instanceWD.get(config.baseURL + 'api/v1/properties', {
        headers: {
          'p-api-key': config.key,
        },
      }),
    );
  },
  sendHubSpot: async (formName: 'brochure_download' | 'contact' | 'floorplan_download') => {
    const formData = new FormData();
    formData.append("API_KEY", "bbcccaec-0aae-4625-bc0e-548e0db890bf");
    formData.append("email", "customer@email.com");
    formData.append("wd_project", 'k2');
    formData.append("wd_form", formName);
    formData.append("is_broker_notified", "0")

    const configReq: any = {
      method: 'post',
      data: formData,
      redirect: 'follow',
    };
    return customFetch(() => instanceHS(configReq))
  }
};

const customFetch = async <T>(func: () => Promise<AxiosResponse<T>>) => {
  try {
    const res = await func();
    return {
      success: true,
      data: res.data,
      code: res.status,
    };
  } catch (e: any) {
    console.error({ e });
    if (e.response && e.response.data && e.response.data.message) {
      throw {
        success: false,
        data: e.response.data.message,
        code: e.response.status,
      };
    }
    throw {
      success: false,
      data: 'error',
      code: 500,
    };
  }
};

export default service;
