import { useEffect, useMemo } from 'react';
import useLocalStorage from './useLocalStorage';

//Hook adds CSS classes if a page is scrolled from the initial position
const useScrollStyles = (classNames: string) => {
  const [scrollPosition, setScrollPosition] = useLocalStorage(
    'scroll_position',
    0,
  );

  useEffect(() => {
    const handleScroll = () => {
      const position = window.scrollY;
      setScrollPosition(position);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const addScrollStyles = useMemo(() => {
    if (scrollPosition > 0) {
      return classNames;
    }
  }, [scrollPosition]);

  return addScrollStyles;
};

export default useScrollStyles;
