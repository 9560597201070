import React from 'react';
import featureImage from '../assets/images/slider/Bedroom.webp';
import feature1Image from '../assets/images/slider/Lounge.webp';
import feature2Image from '../assets/images/slider/Pool.webp';

const Features = () => {
  return (
    <section className="section section-features">
      <div className="container">
        <h3>— FEATURES</h3>
        <div className="pic-text">
          <figure className="pic-text_pic">
            <img src={featureImage} alt="" />
          </figure>
          <article className="pic-text_text">
            K2 features extensive common spaces from the ground floor lobby, courtyard and chill lounge, to the second floor outdoor patio, ninth floor fitness centre, plunge pool and spectacular sky deck.
          </article>
        </div>
        <div className="section-pic">
          <div className="section-pic_left">
            <article className="text">
              Experience the modern conveniences and in-house amenities of this
              groundbreaking complex that joins sun, sky and sea to the vibrant
              urban community of Downtown Kingston.
            </article>
            <ul className="list">
              <li>Exquisite Studio, One and Two bedroom suites</li>
              <li>Breathtaking waterfront views from every apartment</li>
              <li>Pool & outdoor lounge overlooking the Kingston Harbour</li>
              <li>Rooftop Sky Deck</li>
              <li>Gated community with security and CCTV</li>
              <li>Easy access to Downtown, New Kingston and NMIA airport</li>
            </ul>
          </div>
          <div className="two-vertical">
            <figure className="pic">
              <img src={feature1Image} alt="" />
            </figure>
            <figure className="pic">
              <img src={feature2Image} alt="" />
            </figure>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Features;
