import React, { useState } from 'react';
import Modal from '../common/Modal';
import formImage from '../../assets/images/ic-form.svg';
import planImage from '../../assets/images/ic-plan.svg';
import noImage from '../../assets/images/no-images.webp';
import Slider from 'react-slick';
import { IProperty } from '../apartments/Apartments';
import { amenities } from '../apartments/mock/amenities';
import BrochureModal from './BrochureModal';
import useModal from '../../hooks/useModal';
import ContactModal from './ContactModal';
import ThankModal from './ThankModal';
import getImageUrl from '../../utils/getImageUrl';

type Props = {
  onRequestClose: () => void;
  item: IProperty;
};

function SampleNextArrow(props: any) {
  const { className, onClick } = props;
  return (
    <div className={className} onClick={onClick} style={{ right: '1rem' }} />
  );
}

function SamplePrevArrow(props: any) {
  const { className, onClick } = props;
  return (
    <div className={className} onClick={onClick} style={{ left: '1rem' }} />
  );
}

const ApartmentModal = ({ onRequestClose: closeModal, item }: Props) => {
  const [activeTab, setActiveTab] = useState(1);
  const [showContactModal, openContactModal, closeContactModal] =
    useModal(false);
  const [showBrochureModal, openBrochureModal, closeBrochureModal] =
    useModal(false);
  const [showThankModal, openThankModal, closeThankModal] = useModal(false);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    swipe: true,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    customPaging: () => <div className="custom-dot"></div>,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          arrows: false,
        },
      },
    ],
  };

  const getAmenitielByType = (type: string) => {
    const types = type.match(/\d+/g); // [type, subType]
    if (type.toLowerCase() === 'studio') {
      return amenities.studioUnits;
    }
    if (types && Number(types[0]) === 1) {
      return amenities.oneBedroomUnits;
    }
    if (types && Number(types[0]) === 2) {
      return amenities.twoBedroomUnits;
    }
  };

  return (
    <Modal onRequestClose={closeModal}>
      <div id="lot0501" className="apartment-modal">
        <div className="modal-body">
          <div className="modal-header">
            <p className="modal-title">{item.aptNo}</p>
            <button onClick={closeModal} className="modal-close">
              &nbsp;
            </button>
          </div>
          <div className="modal-content">
            <div className="apartment-modal_content">
              <div className="apartment-modal_left">
                <Slider {...settings}>
                  {item.plans.length > 0 ? (
                    item.plans.map((plan) => (
                      <img
                        key={plan.id}
                        src={getImageUrl(plan.name)}
                        alt="plan"
                      />
                    ))
                  ) : (
                    <img src={noImage} alt="plan" />
                  )}
                </Slider>
              </div>
              <div className="section-project_right">
                <div className="switch-container">
                  <div
                    className={`switch-item ${activeTab === 1 ? 'active' : ''}`}
                    onClick={() => setActiveTab(1)}
                  >
                    INFORMATION
                  </div>
                  <div
                    className={`switch-item ${activeTab === 2 ? 'active' : ''}`}
                    onClick={() => setActiveTab(2)}
                  >
                    AMENITIES
                  </div>
                </div>
                {activeTab === 1 ? (
                  <>
                    <div className="info-item">
                      <p className="caption">FLOOR</p>
                      {item.floor}
                    </div>
                    <div className="info-item">
                      <p className="caption">STATUS</p>
                      <span className={`status ${item.status.toLowerCase()}`}>
                        {item.status}
                      </span>
                    </div>
                    <div className="info-item">
                      <p className="caption">TYPE</p>
                      {item.type}
                    </div>
                    <div className="info-item">
                      <p className="caption">SIZE</p>
                      {`${item.surface || ''} Sq. ft.`}
                    </div>
                    <div className="buttons">
                      <a
                        href="https://share.hsforms.com/1kpCtfM3xRm6NRoINbmJoBA3e5iv"
                        target="noopener noreferrer"
                      >
                        <button type="button" className="btn btn-border">
                          <span className="icon">
                            <img src={formImage} alt="icon" />
                          </span>
                          <span>APPLICATION FORM</span>
                        </button>
                      </a>
                      <button
                        type="submit"
                        className="btn btn-border"
                        onClick={openBrochureModal}
                      >
                        <span className="icon">
                          <img src={planImage} alt="floor plan" />
                        </span>
                        DOWNLOAD FLOOR PLAN
                      </button>
                      <button
                        type="submit"
                        className="btn"
                        onClick={openContactModal}
                      >
                        Contact us
                      </button>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="info-item">{item.type}</div>
                    <ul className="amenities-list">
                      {getAmenitielByType(item.type)?.map((item, index) => (
                        <li key={index}>{item}</li>
                      ))}
                    </ul>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {showContactModal && (
        <ContactModal
          onRequestClose={closeContactModal}
          openThankModal={openThankModal}
        />
      )}
      {showBrochureModal && (
        <BrochureModal
          onRequestClose={closeBrochureModal}
          openThankModal={openThankModal}
          isApartment={true}
          roomNumber={item.aptNo.match(/\d+/g)?.pop()}
        />
      )}
      {showThankModal && <ThankModal onClose={closeThankModal} />}
    </Modal>
  );
};

export default ApartmentModal;
