import React, { useEffect, useRef } from 'react';
import panormaImage from '../assets/images/panorama.webp';
import usePanoramaScroll from '../hooks/usePanoramaScroll';

const Location = () => {
  const containerRef = useRef<HTMLDivElement>(null);
  const imageRef = useRef<HTMLImageElement>(null);
  const { handleChangePosition } = usePanoramaScroll(containerRef);

  useEffect(() => {
    const container = containerRef.current;
    const image = imageRef.current;
    if (container && image) {
      setTimeout(() => {
        const containerWidth = container.offsetWidth;
        const imageWidth = image.offsetWidth;
        const initialScrollPosition = imageWidth / 2 - containerWidth / 2;
        container.scrollLeft = initialScrollPosition;
      }, 500);
    }
  }, []);

  return (
    <section className="section section-location" id="location">
      <div className="container">
        <h3>— Location</h3>
        <div className="pic-text_three">
          <div
            ref={containerRef}
            className="panorama-container"
            onDrag={(e) => handleChangePosition(e.screenX)}
          >
            <img
              ref={imageRef}
              src={panormaImage}
              alt="Panorama"
              className="panorama-image"
            />
          </div>
          <article className="pic-text_text">
            Located within the Kingston 2 postal area, on the downtown east, waterfront, the K2 Apartments rise, between Fairbourne Road, Eastbourne Road and Michael Manley Boulevard. Residents will enjoy the convenience of local amenities, proximity to the business districts of Downtown and New Kingston, and the easy and convenient access to the Norman Manley International Airport, and highways to Jamaica’s east, west and north coasts.
          </article>
        </div>
      </div>
    </section>
  );
};

export default Location;
