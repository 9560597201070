import React, { useState } from 'react';
import { Link } from 'react-scroll';
import logoImage from '../assets/images/logo@2x.webp';
import useScrollStyles from '../hooks/useScrollStyles';
import { DURATION, OFFSET } from '../constants';
import ContactModal from './modals/ContactModal';
import BrochureModal from './modals/BrochureModal';
import useModal from '../hooks/useModal';
import ThankModal from './modals/ThankModal';

const Header = () => {
  const [isOpenMenu, setIsOpenMenu] = useState(false);
  const [showContactModal, openContactModal, closeContactModal] =
    useModal(false);
  const [showBrochureModal, openBrochureModal, closeBrochureModal] =
    useModal(false);
  const [showThankModal, openThankModal, closeThankModal] = useModal(false);
  const scrollStyles = useScrollStyles('scroll  header-logo');

  return (
    <>
      <header className={scrollStyles} id="header">
        <div className="header-container">
          <Link
            className="header-logo"
            to="main"
            smooth
            duration={DURATION}
            offset={OFFSET}
          >
            <img src={logoImage} alt="K2 Kingston 2" />
          </Link>
          <span className="btn-menu" onClick={() => setIsOpenMenu(true)}>
            <i></i>
            <i></i>
            <i></i>
          </span>
          <nav className={`header-menu ${isOpenMenu ? 'open' : ''}`}>
            <span
              className="btn-close"
              onClick={() => setIsOpenMenu(false)}
            ></span>
            <ul>
              <li>
                <Link
                  to="project"
                  smooth
                  duration={DURATION}
                  offset={OFFSET}
                  onClick={() => setIsOpenMenu(false)}
                >
                  Project
                </Link>
              </li>
              <li>
                <Link
                  to="gallery"
                  smooth
                  duration={DURATION}
                  offset={OFFSET}
                  onClick={() => setIsOpenMenu(false)}
                >
                  Gallery
                </Link>
              </li>
              <li>
                <Link
                  to="apartment"
                  smooth
                  duration={DURATION}
                  offset={OFFSET}
                  onClick={() => setIsOpenMenu(false)}
                >
                  Apartment
                </Link>
              </li>
              <li>
                <Link
                  to="location"
                  smooth
                  duration={DURATION}
                  offset={OFFSET}
                  onClick={() => setIsOpenMenu(false)}
                >
                  Location
                </Link>
              </li>
              <li>
                <Link
                  to="about"
                  smooth
                  duration={DURATION}
                  offset={OFFSET}
                  onClick={() => setIsOpenMenu(false)}
                >
                  About us
                </Link>
              </li>
            </ul>
            {/*TODO: It should be a button, but written styles don't support it. And href="#" are scrolling page to top  */}
            <a
              type="button"
              onClick={openContactModal}
              className="btn header-contact"
            >
              Contact us
            </a>
          </nav>
          {/*TODO: It should be a button, but written styles don't support it. And href="#" are scrolling page to top */}
          <a type="button" onClick={openBrochureModal} className="btn-download">
            <span>Brochure</span>
          </a>
        </div>
      </header>
      {showContactModal && (
        <ContactModal
          onRequestClose={closeContactModal}
          openThankModal={openThankModal}
        />
      )}
      {showBrochureModal && (
        <BrochureModal
          onRequestClose={closeBrochureModal}
          openThankModal={openThankModal}
        />
      )}
      {showThankModal && <ThankModal onClose={closeThankModal} />}
    </>
  );
};

export default Header;
