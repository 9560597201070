import { useEffect, useState } from 'react';
import { IProperty } from '../../components/apartments/Apartments';
import { useWindowSize } from '../useWindowSize';
import { useFilter } from './useFilter';
import { useSort } from './useSort';

export const useControlData = (data: IProperty[]) => {
  const { width } = useWindowSize();
  const [mode, setMode] = useState<'desktop' | 'mobile'>(
    width > 991 ? 'desktop' : 'mobile',
  );
  const [itemSize, setItemSize] = useState(10);
  const [items, setItems] = useState<IProperty[]>([]);
  const [options, setOptions] = useState<
    { [field: string]: string[] | number[] } | undefined
  >(undefined);
  const { items: sortedElements, sortOptions, handleSort } = useSort(items);
  const {
    items: filteredItems,
    handleFilter,
    defaultValues: defaultFilterValues,
  } = useFilter(data, options);

  useEffect(() => {
    if (width > 991 && mode !== 'desktop') {
      setMode('desktop');
    }
    if (width < 991 && mode !== 'mobile') {
      setMode('mobile');
    }
  }, [width, mode]);

  useEffect(() => {
    if (data.length > 0) {
      setItems(data);
      getOptions();
    }
  }, [data]);

  useEffect(() => {
    setItems(sortedElements);
    setItemSize(10);
  }, [sortedElements]);

  useEffect(() => {
    setItems(filteredItems);
    setItemSize(10);
  }, [filteredItems]);

  const getOptions = () => {
    const newOptions: { [field: string]: string[] | number[] } = {};
    Object.keys(data[0])
      .filter((field) => field !== 'plans')
      .forEach((field) => {
        newOptions[field] = Array.from(
          new Set(
            data
              .filter((el: any) => el[field])
              .map((el: any) => el[field])
              .sort((a, b) => {
                switch (field) {
                  case 'aptNo': {
                    const aNumber = +a.match(/\d+/)![0];
                    const bNumber = +b.match(/\d+/)![0];
                    return aNumber - bNumber;
                  }
                  case 'floor': {
                    if (a === 'Ground floor') {
                      return -1;
                    } else if (b === 'Ground floor') {
                      return 1;
                    } else {
                      return a.localeCompare(b);
                    }
                  }
                  case 'surface':
                  case 'price': {
                    return a - b;
                  }
                  default: {
                    return a.localeCompare(b);
                  }
                }
              }),
          ),
        );
      });

    const createObject = () => {
      const keys = ['floor', 'type', 'surface', 'status'];
      const newObj: any = {};

      keys.forEach((key) => {
        newObj[key] = newOptions[key];
      });

      return { ...newObj };
    };

    setOptions(createObject());
  };

  const handleMore = () => {
    setItemSize((state) => (state += 10));
  };

  return {
    items: mode === 'desktop' ? items : items.slice(0, itemSize),
    isLastItems: items.length <= itemSize,
    options,
    handleMore,
    handleSort,
    handleFilter,
    sortOptions,
    defaultFilterValues,
  };
};
