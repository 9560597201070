import axios from 'axios';
import config from '../config';

const instanceWD = axios.create({
  baseURL: config.baseURLWD,
  //withCredentials: true
});

const instanceHS = axios.create({
  baseURL: config.baseURLHS,
  //withCredentials: true
});

export { instanceWD, instanceHS };
