const useValidation = () => {
  const validation = {
    email: {
      required: 'Required field',
      pattern: {
        value:
          /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        message: 'Wrong email address format',
      },
    },
    required: {
      required: 'Required field',
    },
    phone: {
      pattern: {
        value: /\d{9,12}/,
        message: 'Length must be between 9 and 12',
      },
    },
    message: {
      maxLength: {
        value: 256,
        message: 'Input length exceeds maximum of 256 characters',
      },
    },
  };

  return validation;
};

export default useValidation;
