import React from 'react';
import groupImage from '../assets/images/group.svg';

const About = () => {
  return (
    <section className="section" id="about">
      <div className="container">
        <h3>— about us</h3>
        <div className="section-about">
          <figure className="section-about_pic">
            <img src={groupImage} alt="" />
          </figure>
          <article className="section-about_text">
            <p className="title">
              Incorporated in 1971, Sherbourne Limited is a registered developer of residential real estate.
            </p>
            <p>
              The company’s primary purpose at inception was the subdivision of the land to create the Sherbourne Heights community located in the cool hills of Stony Hill, St Andrew. Along with the expansive subdivision, the company built 23 townhouses, the most recent project completed in 2021 was a private cul-de-sac of eight town homes.
            </p>
            <p>
              The management of the Kingston 2 project will be assigned to its affiliated company, Island Homes Limited (IHL). The directors of IHL, who act in an executive capacity, are Michael Lake and Richard Lake, who individually have over 40 years’ experience in real estate development for both commercial and residential projects. Michael Lake is former president of the Jamaica Developers Association and Richard Lake is the past president of the Master Builders Association.
            </p>
          </article>
        </div>
      </div>
    </section>
  );
};

export default About;
