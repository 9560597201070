import React from 'react';
import welcome1Image from '../assets/images/slider/Living.webp';
import welcome2Image from '../assets/images/slider/Kitchen.webp';

const Project = () => {
  return (
    <section className="section-project" id="project">
      <div className="container">
        <h3>— Project</h3>
        <div className="section-project_content">
          <div className="two-vertical">
            <figure className="pic">
              <img src={welcome1Image} alt="" />
            </figure>
            <figure className="pic">
              <img src={welcome2Image} alt="" />
            </figure>
          </div>
          <div className="section-project_right">
            <p className="title">K2 Apartments</p>
            <div className="info-item">
              <p className="caption">DEVELOPMENT</p>
              119 units with sea view
            </div>
            <div className="info-item">
              <p className="caption">APARTMENT TYPES</p>
              Studio | 1 bedroom | 2 bedroom
            </div>
            <div className="info-item">
              <p className="caption">SURFACE</p>
              374 -1,201 sqft
            </div>
            <div className="info-item">
              <p className="caption">DELIVERY DATE</p>
              Fall 2025
            </div>
            <div className="info-item">
              <p className="caption">PRICE</p>
              From 104,600 USD
            </div>
            <article className="text">
              Welcome to Kingston 2’s stunning ten-storey complex which brings a
              modern refresh to the Kingston Waterfront. K2 offers a whole new
              view of Downtown Kingston and the waterfront lifestyle. Each
              residence offers a unique vista of the Kingston Harbour and Long
              Mountain, with a different sunrise and sunset every day of the
              year.
            </article>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Project;
