import React from 'react';
import team1Image from '../assets/images/sab.webp';
import team2Image from '../assets/images/rc.webp';
import team3Image from '../assets/images/cw.webp';
import islandHomesImage from '../assets/images/logo-ihomes.webp';
import evoHomeImage from '../assets/images/logo-evohome.svg';
import phoneIcon from '../assets/images/ic-phone.webp';

const Footer = () => {
  return (
    <footer className="footer" id="footer">
      <div className="container">
        <div className="footer-contact">
          <p className="footer-title">OUR SALES TEAM</p>
          <div className="footer-left">
            <div className="footer-broker">
              <figure className="footer-broker_pic">
                <img src={team1Image} alt="avatar" />
              </figure>
              <div className="footer-broker_right">
                <p className="name">
                  Sarah-Ann Bowley
                  <span>Associate Broker</span>
                </p>
                <ul className="footer-broker_info">
                  <li className="phone">
                    <span className="phoneIcon">
                      <img src={phoneIcon} alt="phone" /> 1 (876) 797-9855
                    </span>
                  </li>
                  <li className="email">
                    <a href="mailto:salesandrentals@islandhomesja.com">
                      salesandrentals@islandhomesja.com
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="footer-broker">
              <figure className="footer-broker_pic">
                <img src={team2Image} alt="avatar" />
              </figure>
              <div className="footer-broker_right">
                <p className="name">
                  Renae Cole
                  <span>Realtor Associate</span>
                </p>
                <ul className="footer-broker_info">
                  <li className="phone">
                    <span className="phoneIcon">
                      <img src={phoneIcon} alt="phone" /> 1 (876) 418-8233
                    </span>
                  </li>
                  <li className="email">
                    <a href="mailto:RCole@islandhomesja.com">
                      rcole@islandhomesja.com
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="footer-broker">
              <figure className="footer-broker_pic">
                <img src={team3Image} alt="avatar" />
              </figure>
              <div className="footer-broker_right">
                <p className="name">
                  Courtney Williams
                  <span>Sales Representative</span>
                </p>
                <ul className="footer-broker_info">
                  <li className="phone">
                    <span className="phoneIcon">
                      <img src={phoneIcon} alt="phone" /> 1 (876) 452-7297
                    </span>
                  </li>
                  <li className="email">
                    <a href="mailto:cwilliams@islandhomesja.com">
                      cwilliams@islandhomesja.com
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-bottom">
          <div className="footer-address">
            <figure className="footer-address_logo">
              <a
                href="http://www.islandhomesja.com/"
                target="noopener noreferrer"
              >
                <img src={islandHomesImage} alt="Island Homes (logo)" />
              </a>
            </figure>
            <address className="address">
              <p className="name">Island Homes Ltd.</p>
              20 Hope Road, Kingston 10, Jamaica
              <div>Office: 876 929-1153</div>
            </address>
          </div>
        </div>
        <article className="legal-text">
          <p className="main">
            Kingston 2 is a development of Sherbourne Limited, 20 Hope Road, Kingston 10, Registration DV-. The multistory building consists of 54 studios (346 sq ft), 63 1-bedroom (694-802 sq ft) and 2 2-bedroom (1164 sq ft) apartments, constructed of slab roof with polished concrete and laminate flooring. Completion 2025. Subject to escalation. Approved by the Kingston & St Andrew Municipal Corporation in November 2022. Plans can be viewed at the Island Homes Limited office.
          </p>
          <div className="legal-text_bottom">
            <div>
              The images and other details given in this brochure are indicative
              and the developer reserves the right to alter or amend the layout,
              plans, and specifications without prior notice or obligation.
            </div>
            <p className="created">
              This website is created by{' '}
              <a href="https://www.evo-home.ch/" target="noopener noreferrer">
                <img src={evoHomeImage} alt="" />
              </a>
            </p>
          </div>
        </article>
      </div>
    </footer>
  );
};

export default Footer;
