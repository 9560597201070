import React from 'react';
import bgImage from '../assets/images/bg.webp';

const Hero = () => {
  return (
    <main className="section-main" id="main">
      <img src={bgImage} alt="background" className="background" />
      <div className="section-main_container">
        <p className="slogan">Downtown Reimagined</p>
        <a href="#project" className="btn">
          discover more
        </a>
      </div>
    </main>
  );
};

export default Hero;
