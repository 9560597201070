import React from 'react';
import Modal from '../common/Modal';

interface IProps {
  onClose: () => void;
}

const ThankModal = ({ onClose }: IProps) => {
  return (
    <Modal onRequestClose={onClose}>
      <div className="modal-body">
        <div className="modal-header">
          <button onClick={onClose} className="modal-close">
            &nbsp;
          </button>
        </div>
        <div className="contact-form_thank">
          <article className="contact-form_thank-content">
            <p className="title">Thank you!</p>
            <p className="subtitle">
              Thank you for contacting us, your message has been sent.
            </p>
            You will receive an acknowledgment of receipt by email.
            <br />
            If you do not find anything within 10 minutes, please check your
            junk mail.
          </article>
        </div>
      </div>
    </Modal>
  );
};

export default ThankModal;
