import React from 'react';
import Apartments from './components/apartments/Apartments';
import Features from './components/Features';
import Gallery from './components/Gallery';
import Header from './components/Header';
import Hero from './components/Hero';
import Project from './components/Project';
import History from './components/History';
import Location from './components/Location';
import Map from './components/Map';
import About from './components/About';
import Partners from './components/Partners';
import Footer from './components/Footer';
import config from './config';
import TagManager from 'react-gtm-module';
import isBrowser from './utils/isBrowser';

if (config.enableGTM && isBrowser && config.GTM) {
  const GTMArgs = {
    gtmId: config.GTM,
  };
  TagManager.initialize(GTMArgs);
}

const App = () => {
  return (
    <>
      <Header />
      <Hero />
      <Project />
      <Features />
      <Gallery />
      <Apartments />
      <History />
      <Location />
      <Map />
      <About />
      <Partners />
      <Footer />
    </>
  );
};

export default App;
