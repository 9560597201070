import React from 'react';
import mapImage from '../assets/images/maps/map.webp';
import mobileMap1Image from '../assets/images/maps/map1.webp';
import mobileMap2Image from '../assets/images/maps/map2.webp';

const Map = () => {
  return (
    <section className="section-map">
      <div className="container">
        <p className="map-target">2 Fairbourne Road, Bournemouth Gardens, Kingston 2, JA.</p>
        <figure className="map-pic">
          <img src={mapImage} alt="location" className="desctop-map" />
          <img src={mobileMap1Image} alt="location" className="mobile-map" />
          <img src={mobileMap2Image} alt="location" className="mobile-map" />
          <a
            href="https://maps.app.goo.gl/cLgaA4wytTvq67i9A"
            target="noopener noreferrer"
            className="btn btn-google"
          >
            <span>Google maps</span>
          </a>
        </figure>
      </div>
    </section>
  );
};

export default Map;
