import React, { ReactNode, useEffect } from 'react';
import { createPortal } from 'react-dom';

const modalRoot = document.getElementById('modal-root') as HTMLDivElement;

type Props = {
  onRequestClose: () => void;
  children: ReactNode;
};

const Modal = ({ onRequestClose: closeModal, children }: Props) => {
  useEffect(() => {
    /* Closing the modal by Esc key */
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        closeModal();
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    document.body.style.overflow = 'hidden';

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
      document.body.style.overflow = 'unset';
    };
  }, [closeModal]);

  /* Closing the modal by clicking outside the modal */
  const handleClick = (event: any) => {
    if (event.target === event.currentTarget) {
      closeModal();
    }
  };

  return createPortal(
    <div className="modal-overlay" onClick={handleClick}>
      {children}
    </div>,
    modalRoot,
  );
};

export default Modal;
