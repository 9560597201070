import React, { useRef, useState } from 'react';
import apartmentImage from '../../assets/images/pic-apartment.webp';
import { IFloor, IProperty } from './Apartments';
import ApartmentSchema from './ApartmentSchema';

interface IProps {
  data: IProperty[];
  floors: IFloor[];
}

const ApartmentPicSection = ({ data, floors }: IProps) => {
  const [isShowSchema, setIsShowSchema] = useState(false);
  const [currentFloor, setCurrentFloor] = useState('');
  const anchordRef = useRef<HTMLDivElement>(null);

  const scrollToAnchor = () => {
    anchordRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <>
      <figure className="pic-apartment">
        <img src={apartmentImage} alt="" />
        {floors.map((el, index) => (
          <div
            key={index}
            onClick={() => {
              setCurrentFloor(el.name);
              setIsShowSchema(currentFloor === el.name ? !isShowSchema : true);
              setTimeout(scrollToAnchor, 100);
            }}
            className={`floor-mask ${
              el.isAvailable ? 'floor-mask-available' : ''
            } floor${index + 1}`}
          >
            Click to see floor plans
          </div>
        ))}
      </figure>
      {isShowSchema && data.length > 0 && (
        <ApartmentSchema
          anchordRef={anchordRef}
          apartments={data}
          floors={floors}
          currentFloor={currentFloor}
        />
      )}
    </>
  );
};

export default ApartmentPicSection;
