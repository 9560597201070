import React, { useEffect, useState } from 'react';
import service from '../../services/service';
import ApartmentPicSection from './ApartmentPicSection';
import ApartmentTable from './ApartmentTable';

export interface IProperty {
  buildingBlock: string; // "Kingston 2",
  aptNo: string; // "One Bedroom LOT 0807",
  floor: string; // "Ground floor" || "8th Floor",
  type: string; // "studio",
  surface: string; // "32",
  status: string; // "available"
  price: number; // 12000
  plans: {
    id: number;
    name: string;
    ord: number;
    createDate: string;
  }[];
}

export interface IFloor {
  name: string;
  isAvailable: boolean;
}

const Apartments = () => {
  const [data, setData] = useState<IProperty[]>([]);
  const [floors, setFloors] = useState<IFloor[]>([]);

  useEffect(() => {
    service.getProperties().then((res) => {
      const data: any[] = res.data as any[];
      const result: IProperty[] = [];
      const resultFloors: string[] = [];

      const obj: any = {};
      data.forEach((element) => {
        obj[element.id] = element;
      });

      data.forEach((element) => {
        if (element.type.code === 'unit') {
          const property: IProperty | any = {};
          element.fields.forEach((f: any) => {
            property[f.field.code] = f.value;
          });
          property.status = element.status.description;
          property.aptNo = element.title;
          property.plans = element.plans;

          if (element.parent) {
            const floor = obj[element.parent.id];
            property.floor = floor.title;
            if (floor.parent) {
              property.buildingBlock = obj[floor.parent.id].title;
            }
          }
          result.push(property);
        }
        if (element.type.code === 'floor') {
          resultFloors.push(element.title);
        }
      });
      setFloors(
        resultFloors
          .map((item) => ({
            name: item,
            isAvailable: result
              .filter((resItem) => resItem.floor === item)
              .some((item) => item.status.toLowerCase() === 'available'),
          }))
          .sort((a, b) => {
            if (a.name === 'Ground floor') {
              return -1;
            }
            if (b.name === 'Ground floor') {
              return 1;
            }
            return a.name.localeCompare(b.name);
          }),
      );
      setData(result);
    });
  }, []);

  return (
    <>
      <section className="section-table" id="apartment">
        <div className="container">
          <h3>— Apartments</h3>
          <ApartmentPicSection data={data} floors={floors} />
          <ApartmentTable data={data} />
        </div>
      </section>
    </>
  );
};

export default Apartments;
