import { useEffect, useState } from 'react';
import { IProperty } from '../../components/apartments/Apartments';

export type IFilter<T> = {
  [field in keyof T]: string | number;
};

export const useFilter = (
  data: IProperty[],
  options: { [field: string]: string[] | number[] } | undefined,
) => {
  const [items, setItems] = useState<IProperty[]>(data);
  const [filterOptions, setFilterOptions] = useState<IFilter<typeof options>>(
    {},
  );

  useEffect(() => {
    onFilter();
  }, [filterOptions]);

  const handleFilter = (filter: IFilter<typeof options>) => {
    setFilterOptions({ ...filterOptions, ...filter });
  };

  const onFilter = () => {
    let newItems = data;
    const filterFields = filterOptions ? Object.keys(filterOptions) : [];
    if (filterFields.length > 0 && filterOptions) {
      for (const field of filterFields) {
        newItems = newItems.filter((el: any) =>
          filterOptions[field] ? el[field] === filterOptions[field] : true,
        );
      }
    }
    setItems(newItems);
  };

  return { items, handleFilter, defaultValues: filterOptions };
};
