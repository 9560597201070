import React, { useState } from 'react';
import filterImage from '../../assets/images/ic-filter.svg';
import { useControlData } from '../../hooks/table/useControlData';
import useModal from '../../hooks/useModal';
import ApartmentModal from '../modals/ApartmentModal';
import FiltersModal from '../modals/FiltersModal';
import { IProperty } from './Apartments';

interface IProps {
  data: IProperty[];
}

const ApartmentTable = ({ data }: IProps) => {
  const [showFiltersModal, openFiltersModal, closeFiltersModal] =
    useModal(false);
  const [showApartmentModal, openApartmentModal, closeApartmentModal] =
    useModal(false);
  const {
    items,
    isLastItems,
    options,
    sortOptions,
    defaultFilterValues,
    handleSort,
    handleFilter,
    handleMore,
  } = useControlData(data);
  const [selectItem, setSelectItem] = useState<IProperty | undefined>(
    undefined,
  );

  return (
    <>
      <div className="table-info">For more information, click on each floor of the building above or click on the apartment listed below.</div>
      <div className="dropdown-row">
        {options &&
          Object.keys(options).map((field) => (
            <div key={field} className="dropdown">
              <span className="caption">
                {field === 'surface' ? 'SIZE:' : `${field.toUpperCase()}:`}
              </span>
              <select
                className="select"
                onChange={({ target: { value } }) =>
                  handleFilter({ [field]: value })
                }
              >
                <option value={''}>Select</option>
                {options[field].map((item) => (
                  <option key={item} value={item}>
                    {field === 'surface' ? `${item} Sq. ft.` : item}
                  </option>
                ))}
              </select>
            </div>
          ))}
      </div>
      <span className="btn-filters" onClick={openFiltersModal}>
        <span className="text">Filters</span>
        <span className="icon">
          <img src={filterImage} alt="" />
        </span>
      </span>
      <div className="table-desktop">
        <div className="table-top">
          <div
            className={`table-top_td td-number ${
              sortOptions && sortOptions.field === 'aptNo' && !sortOptions.isAsc
                ? 'rotate'
                : ''
            }`}
          >
            <span onClick={() => handleSort('aptNo')}>Apartment No.</span>
          </div>
          <div
            className={`table-top_td td-floor ${
              sortOptions && sortOptions.field === 'floor' && !sortOptions.isAsc
                ? 'rotate'
                : ''
            }`}
          >
            <span onClick={() => handleSort('floor')}>FLOOR</span>
          </div>
          <div
            className={`table-top_td td-type ${
              sortOptions && sortOptions.field === 'type' && !sortOptions.isAsc
                ? 'rotate'
                : ''
            }`}
          >
            <span onClick={() => handleSort('type')}>Type</span>
          </div>
          <div
            className={`table-top_td td-size ${
              sortOptions &&
              sortOptions.field === 'surface' &&
              !sortOptions.isAsc
                ? 'rotate'
                : ''
            }`}
          >
            <span onClick={() => handleSort('surface')}>Size</span>
          </div>
          <div
            className={`table-top_td td-price ${
              sortOptions && sortOptions.field === 'price' && !sortOptions.isAsc
                ? 'rotate'
                : ''
            }`}
          >
            <span onClick={() => handleSort('price')}>Price</span>
          </div>
          <div
            className={`table-top_td td-status ${
              sortOptions &&
              sortOptions.field === 'status' &&
              !sortOptions.isAsc
                ? 'rotate'
                : ''
            }`}
          >
            <span onClick={() => handleSort('status')}>Status</span>
          </div>
        </div>
        <div className="table-scroll scrollbar-inner">
          {items.length > 0 &&
            items.map((item) => (
              <div
                key={item.aptNo}
                className="table-row"
                onClick={() => {
                  setSelectItem(item);
                  openApartmentModal();
                }}
              >
                <div className="table-row_td td-number">{item.aptNo}</div>
                <div className="table-row_td td-floor">{item.floor}</div>
                <div className="table-row_td td-type">{item.type}</div>
                <div className="table-row_td td-size">
                  {item.surface && <span>{item.surface} Sq. ft.</span>}
                </div>
                <div className="table-row_td td-price">
                  {item.price && <span>$ {item.price}</span>}
                </div>
                <div className="table-row_td td-status">
                  <span className={`status ${item.status.toLowerCase()}`}>
                    {item.status}
                  </span>
                </div>
              </div>
            ))}
        </div>
      </div>

      <div className="table-mobile">
        <div className="column-status">
          <div
            className={`td-top ${
              sortOptions &&
              sortOptions.field === 'status' &&
              !sortOptions.isAsc
                ? 'rotate'
                : ''
            }`}
          >
            <span onClick={() => handleSort('status')}>Status</span>
          </div>
          {items.length > 0 &&
            items.map((item) => (
              <div
                key={item.aptNo}
                className="td"
                onClick={() => {
                  setSelectItem(item);
                  openApartmentModal();
                }}
              >
                <span className={`status ${item.status.toLowerCase()}`}>
                  {item.status}
                </span>
              </div>
            ))}
        </div>
        <div className="scrollbar-inner">
          <div className="table-top">
            <div
              className={`table-top_td td-number ${
                sortOptions &&
                sortOptions.field === 'aptNo' &&
                !sortOptions.isAsc
                  ? 'rotate'
                  : ''
              }`}
            >
              <span onClick={() => handleSort('aptNo')}>Apartment No.</span>
            </div>
            <div
              className={`table-top_td td-floor ${
                sortOptions &&
                sortOptions.field === 'floor' &&
                !sortOptions.isAsc
                  ? 'rotate'
                  : ''
              }`}
            >
              <span onClick={() => handleSort('floor')}>FLOOR</span>
            </div>
            <div
              className={`table-top_td td-type ${
                sortOptions &&
                sortOptions.field === 'type' &&
                !sortOptions.isAsc
                  ? 'rotate'
                  : ''
              }`}
            >
              <span onClick={() => handleSort('type')}>Type</span>
            </div>
            <div
              className={`table-top_td td-size ${
                sortOptions &&
                sortOptions.field === 'surface' &&
                !sortOptions.isAsc
                  ? 'rotate'
                  : ''
              }`}
            >
              <span onClick={() => handleSort('surface')}>Size</span>
            </div>
            <div
              className={`table-top_td td-price ${
                sortOptions &&
                sortOptions.field === 'price' &&
                !sortOptions.isAsc
                  ? 'rotate'
                  : ''
              }`}
            >
              <span onClick={() => handleSort('price')}>Price</span>
            </div>
          </div>
          {items.length > 0 &&
            items.map((item) => (
              <div
                key={item.aptNo}
                className="table-row"
                onClick={() => {
                  setSelectItem(item);
                  openApartmentModal();
                }}
              >
                <div className="table-row_td td-number"><span>{item.aptNo}</span></div>
                <div className="table-row_td td-floor">{item.floor}</div>
                <div className="table-row_td td-type">{item.type}</div>
                <div className="table-row_td td-size">
                  <span>{item.surface} Sq. ft.</span>
                </div>
                <div className="table-row_td td-price">
                  <span>$ {item.price}</span>
                </div>
              </div>
            ))}
        </div>
      </div>
      {!isLastItems && (
        <span className="btn btn-light btn-more" onClick={handleMore}>
          Show more
        </span>
      )}
      {showApartmentModal && selectItem && (
        <ApartmentModal
          item={selectItem}
          onRequestClose={closeApartmentModal}
        />
      )}
      {showFiltersModal && options && (
        <FiltersModal
          options={options}
          handleFilter={handleFilter}
          onRequestClose={closeFiltersModal}
          defaultFilterValues={defaultFilterValues}
        />
      )}
    </>
  );
};

export default ApartmentTable;
