import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import useValidation from '../../hooks/useValdation';
import service from '../../services/service';
import InputField from '../common/Input';
import Modal from '../common/Modal';

type Props = {
  onRequestClose: () => void;
  openThankModal: () => void;
  isApartment?: boolean;
  roomNumber?: string;
};

const Brochure = ({
  onRequestClose: closeModal,
  openThankModal,
  isApartment = false,
  roomNumber,
}: Props) => {
  const [isLoading, setIsLoading] = useState(false);
  const validation = useValidation();
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();

  const onSubmit = async (data: any) => {
    setIsLoading(true);
    await service
      .sendHubSpot(isApartment ? 'floorplan_download' : 'brochure_download');
    if (isApartment) {
      data.rooms = roomNumber;
      await service
        .downloadPlan(data)
        .then(() => {
          closeModal();
          openThankModal();
        })
        .finally(() => setIsLoading(false));
    } else {
      await service
        .downloadBrochure(data)
        .then(() => {
          closeModal();
          openThankModal();
        })
        .finally(() => setIsLoading(false));
    }
  };

  return (
    <Modal onRequestClose={() => !isLoading && closeModal()}>
      <div id="downloadBrochure">
        <div className="modal-body" style={{ maxWidth: 674 }}>
          <div className="modal-header">
            <p className="modal-title">
              Download {isApartment ? 'floor plan' : 'brochure'}
            </p>
            <button
              onClick={closeModal}
              className="modal-close"
              disabled={isLoading}
            >
              &nbsp;
            </button>
          </div>
          <div className="modal-content">
            <form className="modal-form" onSubmit={handleSubmit(onSubmit)}>
              <div className="modal-form_columns">
                <InputField
                  errors={errors}
                  type="text"
                  ref={register('firstName', validation.required) as any}
                  name="firstName"
                  placeholder="First name*"
                />
                <InputField
                  errors={errors}
                  type="text"
                  ref={register('lastName', validation.required) as any}
                  name="lastName"
                  placeholder="Last name*"
                />
                <InputField
                  errors={errors}
                  type="text"
                  ref={register('email', validation.email) as any}
                  name="email"
                  placeholder="Email*"
                />
                <InputField
                  errors={errors}
                  type="text"
                  ref={register('phone', validation.phone) as any}
                  name="phone"
                  placeholder="Phone number"
                />
              </div>
              <div
                className={`form-item form-checkbox ${
                  errors.tos ? 'error' : ''
                }`}
              >
                <input
                  type="checkbox"
                  hidden
                  id="checkbox2"
                  {...register('tos', validation.required)}
                />
                <label htmlFor="checkbox2">
                  I have read and accept the privacy policy of this site
                </label>
              </div>
              <button type="submit" className="btn" disabled={isLoading}>
                Download
              </button>
            </form>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default Brochure;
