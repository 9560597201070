export const topFloorCoords = [
  {
    inset: '8.5% 73.3% 56.5% 11.2%',
    clipPath:
      'polygon(0px 0px, 100% 0px, 100% 35%, 85% 35%, 85% 100%, 0px 100%)',
    position: 'right center',
  },
  {
    inset: '44% 76% 45% 11.2%',
    position: 'right center',
  },
  {
    inset: '55.5% 77.9% 12.5% 11.2%',
    clipPath:
      'polygon(0px 0px, 50% 0px, 50% 15%, 100% 15%, 100% 100%, 0px 100%)',
    position: 'right center',
  },
  {
    inset: '55.2% 10.5% 12.5% 72.5%',
    clipPath:
      'polygon(0px 16%, 65% 16%, 65% 0px, 100% 0px, 100% 100%, 0px 100%)',
    position: 'left center',
  },
  {
    inset: '32% 10.5% 45.5% 73.8%',
    clipPath: 'polygon(0 3%, 100% 0, 100% 100%, 16% 100%, 16% 60%, 0% 60%)',
    position: 'left center',
  },
];
export const floorCoords = [
  {
    inset: '9.4% 73% 67.5% 11.2%',
    clipPath:
      'polygon(0px 0px, 100% 0px, 100% 50%, 85% 50%, 85% 100%, 0px 100%)',
    position: 'right center',
  },
  {
    inset: '33% 75.6% 44% 11.2%',
    position: 'right center',
  },
  {
    inset: '56.3% 77.8% 11.5% 11.2%',
    clipPath:
      'polygon(0px 0px, 50% 0px, 50% 15%, 100% 15%, 100% 100%, 0px 100%)',
    position: 'right center',
  },
  {
    inset: '61.5% 66.5% 11.5% 22.5%',
    position: 'right center',
  },
  {
    inset: '61.5% 60.9% 11.5% 33.9%',
    position: 'top center',
  },
  {
    inset: '61.5% 55.2% 11.5% 39.5%',
    position: 'top center',
  },
  {
    inset: '61.5% 49.6% 11.5% 45.3%',
    position: 'top center',
  },
  {
    inset: '61.5% 44% 11.5% 50.9%',
    position: 'top center',
  },
  {
    inset: '61.5% 32.5% 11.3% 62.3%',
    position: 'top center',
  },
  {
    inset: '61.5% 38.3% 11.5% 56.7%',
    position: 'top center',
  },
  {
    inset: '61.5% 21.2% 11.3% 67.7%',
    position: 'left center',
  },
  {
    inset: '56.4% 9.7% 11.5% 79%',
    clipPath:
      'polygon(0px 16%, 50% 16%, 50% 0px, 100% 0px, 100% 100%, 0px 100%)',
    position: 'left center',
  },
  {
    inset: '33% 9.6% 44.6% 74.5%',
    clipPath: 'polygon(0 3%, 100% 0, 100% 100%, 16% 100%, 16% 60%, 0% 60%)',
    position: 'left center',
  },
];
export const groundFloorCoords = [
  {
    inset: '13.5% 78.5% 66.4% 7%',
    clipPath:
      'polygon(0px 0px, 100% 0px, 100% 60%, 85% 60%, 85% 100%, 0px 100%)',
    position: 'right center',
  },
  {
    inset: '34% 80.7% 56.3% 7%',
    position: 'right center',
  },
  {
    inset: '44% 82.9% 28% 7%',
    clipPath:
      'polygon(0px 0px, 50% 0px, 50% 15%, 100% 15%, 100% 100%, 0px 100%)',
    position: 'right center',
  },
  {
    inset: '48.5% 72.2% 28% 17.5%',
    position: 'right center',
  },
  {
    inset: '48.5% 66.9% 28% 28.4%',
    position: 'top center',
  },
  {
    inset: '48.5% 61.5% 28% 33.5%',
    position: 'top center',
  },
  {
    inset: '48.5% 45.7% 28% 49.4%',
    position: 'left center',
  },
  {
    inset: '48.5% 40.4% 28% 54.7%',
    position: 'left center',
  },
  {
    inset: '48.5% 29.8% 28% 60%',
    position: 'left center',
  },
  {
    inset: '44% 19% 28% 70.5%',
    clipPath:
      'polygon(0px 16%, 50% 16%, 50% 0px, 100% 0px, 100% 100%, 0px 100%)',
    position: 'left center',
  },
];
