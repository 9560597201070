import React from 'react';
import mlaImage from '../assets/images/partners/mla.png';
import islandHomesImage from '../assets/images/partners/islandHomes.svg';
import hueLyewChinImage from '../assets/images/partners/HueLyewChin.svg';
import sherbonneLimitedImage from '../assets/images/partners/SherbourneLimited.svg';

const Partners = () => {
  return (
    <section className="section-partners">
      <div className="container">
        <div className="partners-content">
          <h3>— partners</h3>
          <ul className="partners-list">
            <li>
              <figure className="partner-logo partner-full">
                <img src={sherbonneLimitedImage} alt="Sherbonne Limited" />
              </figure>
            </li>
            <li>
              <figure className="partner-logo">
                <img src={mlaImage} alt="mla" />
              </figure>
            </li>
            <li>
              <figure className="partner-logo partner-full">
                <img src={hueLyewChinImage} alt="HLC" />
              </figure>
            </li>
            <li>
              <figure className="partner-logo">
                <a
                  href="http://www.islandhomesja.com/"
                  target="noopener noreferrer"
                >
                  <img src={islandHomesImage} alt="Island Homes" />
                </a>
              </figure>
            </li>
          </ul>
        </div>
      </div>
    </section>
  );
};

export default Partners;
