export const amenities = {
  twoBedroomUnits: [
    'Master bedroom with ensuite bathroom',
    'Open floor plans',
    'Floor-to-ceiling windows',
    'SPC Laminate flooring',
    'Solid surface countertops',
    'Recessed lighting',
    'Contemporary kitchen and bathroom cabinetry and fixtures',
    'Provisioning for telephone and cable',
    'Air-conditioning systems installed in bedrooms',
    'Washer/dryer utility closet',
  ],
  oneBedroomUnits: [
    'Master bedroom with ensuite bathroom',
    'Open floor plans',
    'Floor-to-ceiling windows',
    'SPC Laminate flooring',
    'Solid surface countertops',
    'Recessed lighting',
    'Contemporary kitchen and bathroom cabinetry and fixtures',
    'Provisioning for telephone and cable',
    'Air-conditioning systems installed in bedrooms',
    'Washer/dryer utility closet',
  ],
  studioUnits: [
    'One Bathroom',
    'Open floor plans',
    'Floor-to-ceiling windows',
    'SPC Laminate flooring',
    'Solid surface countertops',
    'Recessed lighting',
    'Contemporary kitchen and bathroom cabinetry and fixtures',
    'Provisioning for telephone and cable',
    'Air-conditioning systems installed in bedrooms',
    'Washer/dryer utility closet',
  ],
};
