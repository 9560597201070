import config from '../config';

function getImageUrl(image: string | undefined) {
  if (!image) {
    return '';
  }
  return `${config.baseURL}api/v1/image/real_binary/${image}`;
}

export default getImageUrl;
